let data = [
  {
    Date: '1-Aug-15',
    'Big Data Management': 25,
    'Data Mining & Analysis': 25,
    'Statistical Inference': 25,
    'Frontend Engineering': 0,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '16-Aug-15',
    'Big Data Management': 25,
    'Data Mining & Analysis': 25,
    'Statistical Inference': 25,
    'Frontend Engineering': 0,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '1-Sep-15',
    'Big Data Management': 25,
    'Data Mining & Analysis': 25,
    'Statistical Inference': 25,
    'Frontend Engineering': 0,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '16-Sep-15',
    'Big Data Management': 25,
    'Data Mining & Analysis': 25,
    'Statistical Inference': 20,
    'Frontend Engineering': 5,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '1-Oct-15',
    'Big Data Management': 23,
    'Data Mining & Analysis': 25,
    'Statistical Inference': 19,
    'Frontend Engineering': 6,
    'Information Visualization': 27,
    total: 100,
  },
  {
    Date: '16-Oct-15',
    'Big Data Management': 22,
    'Data Mining & Analysis': 25,
    'Statistical Inference': 19,
    'Frontend Engineering': 6,
    'Information Visualization': 28,
    total: 100,
  },
  {
    Date: '1-Nov-15',
    'Big Data Management': 20,
    'Data Mining & Analysis': 22,
    'Statistical Inference': 18,
    'Frontend Engineering': 10,
    'Information Visualization': 30,
    total: 100,
  },
  {
    Date: '16-Nov-15',
    'Big Data Management': 18,
    'Data Mining & Analysis': 22,
    'Statistical Inference': 18,
    'Frontend Engineering': 10,
    'Information Visualization': 32,
    total: 100,
  },
  {
    Date: '1-Dec-15',
    'Big Data Management': 17,
    'Data Mining & Analysis': 20,
    'Statistical Inference': 16,
    'Frontend Engineering': 12,
    'Information Visualization': 35,
    total: 100,
  },
  {
    Date: '16-Dec-15',
    'Big Data Management': 11,
    'Data Mining & Analysis': 20,
    'Statistical Inference': 19,
    'Frontend Engineering': 15,
    'Information Visualization': 35,
    total: 100,
  },
  {
    Date: '1-Jan-16',
    'Big Data Management': 10,
    'Data Mining & Analysis': 20,
    'Statistical Inference': 20,
    'Frontend Engineering': 15,
    'Information Visualization': 35,
    total: 100,
  },
  {
    Date: '16-Jan-16',
    'Big Data Management': 11,
    'Data Mining & Analysis': 22,
    'Statistical Inference': 21,
    'Frontend Engineering': 13,
    'Information Visualization': 33,
    total: 100,
  },
  {
    Date: '1-Feb-16',
    'Big Data Management': 18,
    'Data Mining & Analysis': 22,
    'Statistical Inference': 20,
    'Frontend Engineering': 10,
    'Information Visualization': 30,
    total: 100,
  },
  {
    Date: '16-Feb-16',
    'Big Data Management': 20,
    'Data Mining & Analysis': 24,
    'Statistical Inference': 18,
    'Frontend Engineering': 10,
    'Information Visualization': 28,
    total: 100,
  },
  {
    Date: '1-Mar-16',
    'Big Data Management': 22,
    'Data Mining & Analysis': 25,
    'Statistical Inference': 20,
    'Frontend Engineering': 7,
    'Information Visualization': 26,
    total: 100,
  },
  {
    Date: '16-Mar-16',
    'Big Data Management': 25,
    'Data Mining & Analysis': 25,
    'Statistical Inference': 20,
    'Frontend Engineering': 5,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '1-Apr-16',
    'Big Data Management': 27,
    'Data Mining & Analysis': 28,
    'Statistical Inference': 15,
    'Frontend Engineering': 5,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '16-Apr-16',
    'Big Data Management': 30,
    'Data Mining & Analysis': 30,
    'Statistical Inference': 12,
    'Frontend Engineering': 8,
    'Information Visualization': 20,
    total: 100,
  },
  {
    Date: '1-May-16',
    'Big Data Management': 27,
    'Data Mining & Analysis': 27,
    'Statistical Inference': 16,
    'Frontend Engineering': 10,
    'Information Visualization': 20,
    total: 100,
  },
  {
    Date: '16-May-16',
    'Big Data Management': 25,
    'Data Mining & Analysis': 25,
    'Statistical Inference': 15,
    'Frontend Engineering': 12,
    'Information Visualization': 23,
    total: 100,
  },
  {
    Date: '1-Jun-16',
    'Big Data Management': 23,
    'Data Mining & Analysis': 18,
    'Statistical Inference': 18,
    'Frontend Engineering': 16,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '16-Jun-16',
    'Big Data Management': 20,
    'Data Mining & Analysis': 15,
    'Statistical Inference': 15,
    'Frontend Engineering': 20,
    'Information Visualization': 30,
    total: 100,
  },
  {
    Date: '1-Jul-16',
    'Big Data Management': 17,
    'Data Mining & Analysis': 12,
    'Statistical Inference': 15,
    'Frontend Engineering': 23,
    'Information Visualization': 33,
    total: 100,
  },
  {
    Date: '16-Jul-16',
    'Big Data Management': 15,
    'Data Mining & Analysis': 12,
    'Statistical Inference': 15,
    'Frontend Engineering': 23,
    'Information Visualization': 35,
    total: 100,
  },
  {
    Date: '1-Aug-16',
    'Big Data Management': 13,
    'Data Mining & Analysis': 10,
    'Statistical Inference': 15,
    'Frontend Engineering': 25,
    'Information Visualization': 37,
    total: 100,
  },
  {
    Date: '16-Aug-16',
    'Big Data Management': 10,
    'Data Mining & Analysis': 10,
    'Statistical Inference': 13,
    'Frontend Engineering': 27,
    'Information Visualization': 40,
    total: 100,
  },
  {
    Date: '1-Sep-16',
    'Big Data Management': 8,
    'Data Mining & Analysis': 8,
    'Statistical Inference': 10,
    'Frontend Engineering': 30,
    'Information Visualization': 43,
    total: 99,
  },
  {
    Date: '16-Sep-16',
    'Big Data Management': 12,
    'Data Mining & Analysis': 5,
    'Statistical Inference': 10,
    'Frontend Engineering': 29,
    'Information Visualization': 44,
    total: 100,
  },
  {
    Date: '1-Oct-16',
    'Big Data Management': 15,
    'Data Mining & Analysis': 5,
    'Statistical Inference': 5,
    'Frontend Engineering': 30,
    'Information Visualization': 45,
    total: 100,
  },
  {
    Date: '16-Oct-16',
    'Big Data Management': 20,
    'Data Mining & Analysis': 5,
    'Statistical Inference': 5,
    'Frontend Engineering': 25,
    'Information Visualization': 45,
    total: 100,
  },
  {
    Date: '1-Nov-16',
    'Big Data Management': 23,
    'Data Mining & Analysis': 5,
    'Statistical Inference': 2,
    'Frontend Engineering': 22,
    'Information Visualization': 48,
    total: 100,
  },
  {
    Date: '16-Nov-16',
    'Big Data Management': 25,
    'Data Mining & Analysis': 5,
    'Statistical Inference': 0,
    'Frontend Engineering': 20,
    'Information Visualization': 50,
    total: 100,
  },
  {
    Date: '1-Dec-16',
    'Big Data Management': 22,
    'Data Mining & Analysis': 0,
    'Statistical Inference': 0,
    'Frontend Engineering': 23,
    'Information Visualization': 55,
    total: 100,
  },
  {
    Date: '16-Dec-16',
    'Big Data Management': 20,
    'Data Mining & Analysis': 5,
    'Statistical Inference': 0,
    'Frontend Engineering': 25,
    'Information Visualization': 50,
    total: 100,
  },
  {
    Date: '1-Jan-17',
    'Big Data Management': 17,
    'Data Mining & Analysis': 15,
    'Statistical Inference': 20,
    'Frontend Engineering': 18,
    'Information Visualization': 33,
    total: 100,
  },
  {
    Date: '16-Jan-17',
    'Big Data Management': 15,
    'Data Mining & Analysis': 15,
    'Statistical Inference': 25,
    'Frontend Engineering': 15,
    'Information Visualization': 30,
    total: 100,
  },
  {
    Date: '1-Feb-17',
    'Big Data Management': 15,
    'Data Mining & Analysis': 20,
    'Statistical Inference': 30,
    'Frontend Engineering': 10,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '16-Feb-17',
    'Big Data Management': 10,
    'Data Mining & Analysis': 20,
    'Statistical Inference': 35,
    'Frontend Engineering': 10,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '1-Mar-17',
    'Big Data Management': 5,
    'Data Mining & Analysis': 20,
    'Statistical Inference': 40,
    'Frontend Engineering': 10,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '16-Mar-17',
    'Big Data Management': 5,
    'Data Mining & Analysis': 15,
    'Statistical Inference': 45,
    'Frontend Engineering': 10,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '1-Apr-17',
    'Big Data Management': 5,
    'Data Mining & Analysis': 15,
    'Statistical Inference': 40,
    'Frontend Engineering': 12,
    'Information Visualization': 28,
    total: 100,
  },
  {
    Date: '16-Apr-17',
    'Big Data Management': 10,
    'Data Mining & Analysis': 10,
    'Statistical Inference': 35,
    'Frontend Engineering': 15,
    'Information Visualization': 30,
    total: 100,
  },
  {
    Date: '1-May-17',
    'Big Data Management': 15,
    'Data Mining & Analysis': 15,
    'Statistical Inference': 30,
    'Frontend Engineering': 15,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '16-May-17',
    'Big Data Management': 20,
    'Data Mining & Analysis': 15,
    'Statistical Inference': 25,
    'Frontend Engineering': 15,
    'Information Visualization': 25,
    total: 100,
  },
  {
    Date: '1-Jun-17',
    'Big Data Management': 15,
    'Data Mining & Analysis': 15,
    'Statistical Inference': 20,
    'Frontend Engineering': 20,
    'Information Visualization': 30,
    total: 100,
  },
  {
    Date: '16-Jun-17',
    'Big Data Management': 15,
    'Data Mining & Analysis': 15,
    'Statistical Inference': 15,
    'Frontend Engineering': 25,
    'Information Visualization': 30,
    total: 100,
  },
  {
    Date: '1-Jul-17',
    'Big Data Management': 17,
    'Data Mining & Analysis': 8,
    'Statistical Inference': 15,
    'Frontend Engineering': 25,
    'Information Visualization': 35,
    total: 100,
  },
  {
    Date: '16-Jul-17',
    'Big Data Management': 10,
    'Data Mining & Analysis': 5,
    'Statistical Inference': 15,
    'Frontend Engineering': 35,
    'Information Visualization': 35,
    total: 100,
  },
  {
    Date: '1-Aug-17',
    'Big Data Management': 10,
    'Data Mining & Analysis': 5,
    'Statistical Inference': 10,
    'Frontend Engineering': 35,
    'Information Visualization': 40,
    total: 100,
  },
  {
    Date: '16-Aug-17',
    'Big Data Management': 10,
    'Data Mining & Analysis': 10,
    'Statistical Inference': 5,
    'Frontend Engineering': 40,
    'Information Visualization': 45,
    total: 100,
  },
]

export default data
